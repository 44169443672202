import Vue from 'vue';
import Vuex from 'vuex';
import store from 'store';

Vue.use(Vuex);

const STORED_SETTINGS = (storedSettings) => {
  const settings = {};
  Object.keys(storedSettings).forEach((key) => {
    const item = store.get(`app.settings.${key}`);
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key];
  });
  return settings;
};

export default {
  state: {
    ...STORED_SETTINGS({
      logo: 'GIA Repse',
      locale: 'es-ES',
      theme: 'default', // default, dark
      primaryColor: '#A0160A',
      primaryDarkColor: '#8a1208',
      primaryLightColor: '#b91b0d',
    }),
  },
  mutations: {
    CHANGE_SETTING(state, payload) {
      window.sessionStorage.setItem(`app.settings.${payload.setting}`, payload.value);
      state[payload.setting] = payload.value;
    },
    SETUP_URL_SETTINGS(state, payload) {
      let queryParams = payload;
      let keys = false;
      if (payload.redirect) {
        const str = payload.redirect;
        const subs = str.substring(str.indexOf('?') + 1);
        if (str.indexOf('?') >= 0) {
          queryParams = JSON.parse(`{"${decodeURI(subs).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);
        }
      }
      delete queryParams.redirect;
      keys = Object.keys(queryParams);
      if (keys.length) {
        keys.forEach((key) => {
          let value;
          switch (queryParams[key]) {
            case 'false':
              value = false;
              break;
            case 'true':
              value = true;
              break;
            default:
              value = queryParams[key];
              break;
          }
          if (key in state) { state[key] = value; }
        });
      }
    },
    SET_PRIMARY_COLOR(state, payload) {
      const { color } = payload;
      const addStyles = () => {
        const styleElement = document.querySelector('#primaryColor');
        if (styleElement) {
          styleElement.remove();
        }
        const body = document.querySelector('body');
        const styleEl = document.createElement('style');
        const css = document.createTextNode(`:root { --kit-color-primary: ${color};}`);
        styleEl.setAttribute('id', 'primaryColor');
        styleEl.appendChild(css);
        body.appendChild(styleEl);
      };
      addStyles();
      state.primaryColor = color;
      store.set('app.settings.primaryColor', color);
    },
    SET_PRIMARYDARK_COLOR(state, payload) {
      const { color } = payload;
      const addStyles = () => {
        const styleElement = document.querySelector('#primaryDarkColor');
        if (styleElement) {
          styleElement.remove();
        }
        const body = document.querySelector('body');
        const styleEl = document.createElement('style');
        const css = document.createTextNode(`:root { --kit-color-primary-dark: ${color};}`);
        styleEl.setAttribute('id', 'primaryDarkColor');
        styleEl.appendChild(css);
        body.appendChild(styleEl);
      };
      addStyles();
      state.primaryDarkColor = color;
      store.set('app.settings.primaryDarkColor', color);
    },
    SET_PRIMARYLIGHT_COLOR(state, payload) {
      const { color } = payload;
      const addStyles = () => {
        const styleElement = document.querySelector('#primaryLightColor');
        if (styleElement) {
          styleElement.remove();
        }
        const body = document.querySelector('body');
        const styleEl = document.createElement('style');
        const css = document.createTextNode(`:root { --kit-color-primary-light: ${color};}`);
        styleEl.setAttribute('id', 'primaryLightColor');
        styleEl.appendChild(css);
        body.appendChild(styleEl);
      };
      addStyles();
      state.primaryLightColor = color;
      store.set('app.settings.primaryLightColor', color);
    },
    SET_THEME(state, payload) {
      const { theme } = payload;
      const nextTheme = theme === 'dark' ? 'dark' : 'default';
      document.querySelector('html').setAttribute('data-kit-theme', nextTheme);
      state.theme = nextTheme;
      store.set('app.settings.theme', nextTheme);
    },
  },
  actions: {},
  getters: {
    state: (state) => state,
  },
};
