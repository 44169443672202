/* eslint-disable import/no-cycle */
import Vue from 'vue';
import VueRouter from 'vue-router';
import apiClient from '@/services/axios';
import store from '@/store';

import AuthLayout from '@/layouts/Auth.vue';
import MainLayout from '@/layouts/Main.vue';

Vue.use(VueRouter);

const routes = [
  // System Pages
  {
    path: '/auth',
    component: AuthLayout,
    redirect: '/auth/login',
    children: [
      /*
      {
      path: '/auth/500',
      meta: {
          title: 'Error 500',
      },
      component: () =>
          import ('./views/system/errors/500'),
      },
      */
      {
        path: '/auth/register',
        name: 'Register',
        meta: {
          title: 'Registar',
        },
        component: () => import('../views/system/auth/Register.vue'),
      },
      {
        path: '/auth/login',
        name: 'Login',
        meta: {
          title: 'Iniciar sesión',
        },
        component: () => import('../views/system/auth/Login.vue'),
      },
      {
        path: '/auth/forgot-password',
        meta: {
          title: 'Recuperar contraseña',
        },
        component: () => import('../views/system/auth/ForgotPassword.vue'),
      },
      {
        path: '/auth/reset-password/:token',
        meta: {
          title: 'Restablecer contraseña',
        },
        component: () => import('../views/system/auth/ResetPassword.vue'),
      },
      /*
      {
          path: '/auth/lockscreen',
          meta: {
              title: 'Pantalla de Bloqueo',
          },
          component: () =>
              import ('./views/system/auth/lockscreen'),
      },
      */
    ],
  },
  {
    path: '',
    name: '',
    component: MainLayout,
    redirect: '/dashboard',
    meta: {
      authRequired: true,
    },
    children: [
      {
        name: '/dashboard',
        path: '/dashboard',
        meta: {
          title: 'Dashboard',
          slug: 'dashboard',
        },
        component: () => import('../views/Dashboard.vue'),
      },
      {
        name: '/perfil',
        path: '/perfil',
        meta: {
          title: 'Perfil',
          slug: 'perfil',
        },
        props: true,
        component: () => import('../views/Perfil.vue'),
      },
      {
        name: '/ordenes',
        path: '/ordenes',
        meta: {
          title: 'Ordenes',
          slug: 'ordenes',
        },
        props: true,
        component: () => import('../views/Ordenes.vue'),
      },
      {
        name: '/ordenes/detalle',
        path: '/ordenes/detalle',
        meta: {
          title: 'Detalle de la Orden',
          slug: 'ordenes-edit',
          auth: ['ordenes', ['Ver', 'Editar']],
        },
        props: true,
        component: () => import('../views/OrdenDetalle.vue'),
      },
      {
        name: '/ordenes/detalle/cargar',
        path: '/ordenes/detalle/cargar',
        meta: {
          title: 'Cargar archivos',
          slug: 'ordenes-cargar',
          auth: ['ordenes', ['Ver', 'Editar', 'Cargar']],
        },
        props: true,
        component: () => import('../views/CargarArchivos.vue'),
      },
      {
        name: '/facturas',
        path: '/facturas',
        meta: {
          title: 'CFDIS',
          slug: 'facturas',
        },
        props: true,
        component: () => import('../views/Facturas.vue'),
      },
      {
        name: '/facturas-todas',
        path: '/facturas-todas',
        meta: {
          title: 'Cfdis Listado Completo',
          slug: 'facturas-todas',
        },
        props: true,
        component: () => import('../views/FacturasTodas.vue'),
      },
      {
        name: '/usuarios',
        path: '/usuarios',
        meta: {
          title: 'Usuarios',
          slug: 'usuarios',
        },
        component: () => import('../views/administrar/Usuarios.vue'),
      },
      {
        name: '/perfiles',
        path: '/perfiles',
        meta: {
          title: 'Perfiles de Usuario',
          slug: 'perfiles',
        },
        component: () => import('../views/administrar/Perfiles.vue'),
      },
      {
        name: '/permisos',
        path: '/permisos',
        meta: {
          title: 'Permisos de Usuarios',
          slug: 'permisos',
        },
        component: () => import('../views/administrar/Permisos.vue'),
      },
      {
        name: '/empresas',
        path: '/empresas',
        meta: {
          title: 'Empresas contratantes',
          slug: 'empresas',
        },
        component: () => import('../views/administrar/Empresas.vue'),
      },
      {
        name: '/correos-aut',
        path: '/correos-aut',
        meta: {
          title: 'Correos autorizados',
          slug: 'correos-auth',
        },
        component: () => import('../views/administrar/CorreosAut.vue'),
      },
      {
        name: '/bitacora',
        path: '/bitacora',
        meta: {
          title: 'Bitacora',
          slug: 'bitacora',
        },
        component: () => import('../views/Bitacora.vue'),
      },
    ],
  },
  {
    path: '/406',
    name: '406',
    hidden: true,
    meta: {
      title: 'Pais NO autorizado',
    },
    component: () => import('../views/system/errors/Error406.vue'),
  },
  {
    path: '503',
    name: '503',
    hidden: true,
    meta: {
      title: 'Error 503',
    },
    component: () => import('../views/system/errors/Error503.vue'),
  },
  {
    path: '*',
    name: '404',
    hidden: true,
    meta: {
      title: 'Error 404',
    },
    component: () => import('../views/system/errors/Error404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
});

router.onError((err) => console.log(err));

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (!store.state.user.authorized) {
      next({
        path: '/auth',
        query: { redirect: to.fullPath },
      });
    } else if (to.meta.slug) {
      /** Se verifica que la ruta de vue tenga el atributo meta.slug y enseguida se llama a la api /secciones/slug para validar en backend si se cuenta con el permiso a la sección
       *  caso contrario se redirige a la  pagina raiz, el atributo meta.parent_slug
       */
      let slug;
      let permission;
      /**
       * Se verifica si la ruta tiene el atributo meta.auth , si es asi significa que la ruta es una subseccion
       * y que su acceso depende de los permisos (ver, agregar, editar, eliminar, cargar, descargar), por ejemplo la view "Nuevo Seguro" no viene en
       * las secciones y se accede a ella a traves de un boton de accion desde otra vista
       */
      if (to.meta.auth) {
        // eslint-disable-next-line prefer-destructuring
        slug = to.meta.auth[0]; // slug de la seccion
        // eslint-disable-next-line prefer-destructuring
        permission = to.meta.auth[1]; // arreglo de permisos en tabla enlace_perfiles_permisos (ver, agregar, editar, eliminar, ...etc)
      } else {
        /**
        * Si NO tiene el atributo meta.auth entonces se verifica su acceso con verSeccionMiddleware
        */
        slug = to.meta.slug;
        permission = ['Ver'];
      }

      apiClient.post('/secciones/show', {
        permisos: permission,
        slug,
      }).then((res) => {
        // eslint-disable-next-line no-param-reassign
        to.query.viewtype = res.data?.viewtype || 0;
        next();
      }).catch((err) => {
        // console.log('Error al verificar permiso a la sección.');
        if (err.response.status === 406) {
          next({ path: '/406' });
        } else if (err.response.status === 503) {
          next({ path: '/503' });
        } else if (store.state.user.es_proveedor) next({ path: '/perfil' });
        else next({ path: '/' });
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
