import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line import/no-cycle
import user from './user';
import settings from './settings';
import globals from './globals';
// eslint-disable-next-line import/no-cycle
import aggrid from './aggrid';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    settings,
    globals,
    aggrid,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {
    user: (state) => state,
  },
});
