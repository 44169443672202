import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import './global.scss'; // app & third-party component styles
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEnvelope,
  faExclamationCircle,
  faSpinner,
  faFolderOpen,
  faEdit,
  faBuilding,
  faIdCard,
  faMapMarkerAlt,
  faUser,
  faMobileAlt,
  faFilePdf,
  faUpload,
  faUserTie,
  faPlus,
  faCheckCircle,
  faTimesCircle,
  faCalendarAlt,
  faListAlt,
  faArrowLeft,
  faDownload,
  faFileArchive,
  faEye,
  faEyeSlash,
  faLock,
  faTrashAlt,
  faUsers,
  faUserTag,
  faUserLock,
  faEnvelopeSquare,
  faCloudUploadAlt,
  faSave,
  faPaperPlane,
  faFileInvoice,
  faEnvelopeOpenText,
  faFileContract,
  faGlobe,
  faFileCode,
  faFileInvoiceDollar,
  faReceipt,
  faExclamationTriangle,
  faPowerOff,
  faInfoCircle,
  faSyncAlt,
  faClock,
  faPlusCircle,
  faPlayCircle,
  faStopCircle,
  faLockOpen,
  faFileCircleCheck,
  faFileCircleXmark,
  faFileExcel,
  faTriangleExclamation,

} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VuePageTitle from 'vue-page-title';
import NProgress from 'vue-nprogress';
import SimpleVueValidation from 'simple-vue-validator';
import Message from 'vue-m-message';
import 'vue-m-message/dist/index.css';
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import VScrollToTop from 'v-scroll-to-top';
import JsonViewer from 'vue-json-viewer';
import 'vue-json-viewer/style.css';
import VueEllipseProgress from 'vue-ellipse-progress';
import VueShortKey from 'vue-shortkey';
import { LicenseManager } from 'ag-grid-enterprise';
import DisableDevtool from 'disable-devtool';
import VueOnlineProp from './mixins/network';

import App from './App.vue';
import router from './router';
import store from './store';

import Functions from './mixins/functions';
import Permisos from './mixins/permisos';

/** AG-GRID Enterprise License */
LicenseManager.setLicenseKey('MjAwMDAwMDAwMDAwMA==598447838c89eb4366146127615e40a4');

library.add(faEnvelope);
library.add(faFacebookF);
library.add(faLinkedinIn);
library.add(faInstagram);
library.add(faYoutube);
library.add(faExclamationCircle);
library.add(faSpinner);
library.add(faFolderOpen);
library.add(faEdit);
library.add(faBuilding);
library.add(faIdCard);
library.add(faMapMarkerAlt);
library.add(faUser);
library.add(faMobileAlt);
library.add(faFilePdf);
library.add(faUpload);
library.add(faUserTie);
library.add(faPlus);
library.add(faCheckCircle);
library.add(faTimesCircle);
library.add(faCalendarAlt);
library.add(faListAlt);
library.add(faArrowLeft);
library.add(faDownload);
library.add(faFileArchive);
library.add(faEye);
library.add(faEyeSlash);
library.add(faLock);
library.add(faTrashAlt);
library.add(faUsers);
library.add(faUserTag);
library.add(faUserLock);
library.add(faEnvelopeSquare);
library.add(faCloudUploadAlt);
library.add(faSave);
library.add(faPaperPlane);
library.add(faFileInvoice);
library.add(faEnvelopeOpenText);
library.add(faFileContract);
library.add(faGlobe);
library.add(faFileCode);
library.add(faFileInvoiceDollar);
library.add(faReceipt);
library.add(faExclamationTriangle);
library.add(faPowerOff);
library.add(faInfoCircle);
library.add(faSyncAlt);
library.add(faClock);
library.add(faPlusCircle);
library.add(faPlayCircle);
library.add(faStopCircle);
library.add(faLockOpen);
library.add(faFileCircleCheck);
library.add(faFileCircleXmark);
library.add(faFileExcel);
library.add(faTriangleExclamation);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(NProgress);
Vue.use(SimpleVueValidation);
Vue.use(Message, { name: 'msg' });
Vue.use(VueFileAgent);
Vue.component('v-select', vSelect);
Vue.use(DatePicker);
Vue.use(VScrollToTop);
Vue.use(JsonViewer);
Vue.use(VueEllipseProgress);
Vue.use(VueShortKey);
Vue.use(VueOnlineProp);
Vue.use(VuePageTitle, {
  prefix: 'GIA Proveedores | ',
  router,
});
Vue.mixin(Functions);
Vue.mixin(Permisos);

Vue.config.productionTip = false;

const nprogress = new NProgress({ parent: 'body' });

// eslint-disable-next-line no-undef
if (CONFIG.APP_DEBUG !== true) {
  DisableDevtool({
    disableMenu: false,
    // eslint-disable-next-line no-undef
    url: CONFIG.URL_NODEV,
  });
}

new Vue({
  router,
  store,
  nprogress,
  render: (h) => h(App),
}).$mount('#app');
