/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
export default {
  methods: {
    $ver(permisos) {
      const permisos_user = this.$store.getters.user.user.permisos;

      const permisos_find = permisos.map((e) => {
        const permiso = permisos_user.find((per) => per.NomPermiso.trim() == e.trim() && per.Ver == 1);
        return permiso;
      });

      const permisos_filter = permisos_find.filter((p) => p != null);

      if (permisos_filter.length <= 0) {
        return false;
      }
      return true;
    },
    $agregar(permisos) {
      const permisos_user = this.$store.getters.user.user.permisos;

      const permisos_find = permisos.map((e) => {
        const permiso = permisos_user.find((per) => per.NomPermiso.trim() == e.trim() && per.Agregar == 1);
        return permiso;
      });

      const permisos_filter = permisos_find.filter((p) => p != null);

      if (permisos_filter.length <= 0) {
        return false;
      }
      return true;
    },
    $editar(permisos) {
      const permisos_user = this.$store.getters.user.user.permisos;

      const permisos_find = permisos.map((e) => {
        const permiso = permisos_user.find((per) => per.NomPermiso.trim() == e.trim() && per.Editar == 1);
        return permiso;
      });

      const permisos_filter = permisos_find.filter((p) => p != null);

      if (permisos_filter.length <= 0) {
        return false;
      }
      return true;
    },
    $eliminar(permisos) {
      const permisos_user = this.$store.getters.user.user.permisos;

      const permisos_find = permisos.map((e) => {
        const permiso = permisos_user.find((per) => per.NomPermiso.trim() == e.trim() && per.Eliminar == 1);
        return permiso;
      });

      const permisos_filter = permisos_find.filter((p) => p != null);

      if (permisos_filter.length <= 0) {
        return false;
      }
      return true;
    },
    $imprimir(permisos) {
      const permisos_user = this.$store.getters.user.user.permisos;

      const permisos_find = permisos.map((e) => {
        const permiso = permisos_user.find((per) => per.NomPermiso.trim() == e.trim() && per.Imprimir == 1);
        return permiso;
      });

      const permisos_filter = permisos_find.filter((p) => p != null);

      if (permisos_filter.length <= 0) {
        return false;
      }
      return true;
    },
    $cargar(permisos) {
      const permisos_user = this.$store.getters.user.user.permisos;

      const permisos_find = permisos.map((e) => {
        const permiso = permisos_user.find((per) => per.NomPermiso.trim() == e.trim() && per.Cargar == 1);
        return permiso;
      });

      const permisos_filter = permisos_find.filter((p) => p != null);

      if (permisos_filter.length <= 0) {
        return false;
      }
      return true;
    },
    $descargar(permisos) {
      const permisos_user = this.$store.getters.user.user.permisos;

      const permisos_find = permisos.map((e) => {
        const permiso = permisos_user.find((per) => per.NomPermiso.trim() == e.trim() && per.Descargar == 1);
        return permiso;
      });

      const permisos_filter = permisos_find.filter((p) => p != null);

      if (permisos_filter.length <= 0) {
        return false;
      }
      return true;
    },
    $importar(permisos) {
      const permisos_user = this.$store.getters.user.user.permisos;

      const permisos_find = permisos.map((e) => {
        const permiso = permisos_user.find((per) => per.NomPermiso.trim() == e.trim() && per.Importar == 1);
        return permiso;
      });

      const permisos_filter = permisos_find.filter((p) => p != null);

      if (permisos_filter.length <= 0) {
        return false;
      }
      return true;
    },
    $exportar(permisos) {
      const permisos_user = this.$store.getters.user.user.permisos;

      const permisos_find = permisos.map((e) => {
        const permiso = permisos_user.find((per) => per.NomPermiso.trim() == e.trim() && per.Exportar == 1);
        return permiso;
      });

      const permisos_filter = permisos_find.filter((p) => p != null);

      if (permisos_filter.length <= 0) {
        return false;
      }
      return true;
    },
    $validar(permisos) {
      const permisos_user = this.$store.getters.user.user.permisos;

      const permisos_find = permisos.map((e) => {
        const permiso = permisos_user.find((per) => per.NomPermiso.trim() == e.trim() && per.Validar == 1);
        return permiso;
      });

      const permisos_filter = permisos_find.filter((p) => p != null);

      if (permisos_filter.length <= 0) {
        return false;
      }
      return true;
    },
    $permisos_or(permisos) {
      const permisos_user = this.$store.getters.user.user.permisos;

      const permisos_find = permisos.map((e) => {
        /**
               * @e[0] es el nombre del permiso a comprar
               * @e[1] es el tipo de permiso (ver, agregar, editar, eliminar, imprimir, cargar, descargar)
               */
        const permiso = permisos_user.find((per) => per.NomPermiso.trim() == e[0].trim() && per[e[1]] == 1);
        return permiso;
      });

      const permisos_filter = permisos_find.filter((p) => p != null);

      if (permisos_filter.length <= 0) {
        return false;
      }
      return true;
    },
    $puede_cargar() {
      const { puede_cargar } = this.$store.getters.user.user;
      if (!puede_cargar) {
        return false;
      }
      return true;
    },
    $fecha_limcarga() {
      const { fecha_limcarga } = this.$store.getters.user.user;
      if (!fecha_limcarga) {
        return false;
      }
      return fecha_limcarga;
    },
    $fecha_libcarga() {
      const { fecha_libcarga } = this.$store.getters.user.user;
      if (!fecha_libcarga) {
        return false;
      }
      return fecha_libcarga;
    },
  },
};
