/* eslint-disable arrow-parens */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import store from '@/store/user';
import router from '@/router';
import Message from 'vue-m-message';

Message.globals.options.showClose = true;
Message.globals.options.supportHTML = true;
Message.globals.options.duration = 5000;

const apiClient = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: CONFIG.API_URL,
  // baseURL: process.env.VUE_APP_API_URL,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
});

apiClient.interceptors.request.use((request) => {
  router.app.$nprogress.start();
  // Se agrega el token de session almacenado en sessionStorage en cada peticion de axios
  const accessToken = sessionStorage.getItem('session');
  if (accessToken) {
    request.headers.Authorization = accessToken;
  }

  return request;
});

apiClient.interceptors.response.use((response) => {
  router.app.$nprogress.done();
  const { data } = response;
  if (data.msg) { Message.success(data.msg); }
  return response;
}, (error) => {
  router.app.$nprogress.done();
  return Promise.reject(error);
});

apiClient.interceptors.response.use(undefined, error => {
  Message.closeAll();
  router.app.$nprogress.done();
  // Errors handling
  const { response } = error;
  const { data } = response;

  // Verificar si no existe el error 429 Too Many Request
  if (response.status === 429) {
    Message.error(data ?? 'Supero el limite de solicitudes por minuto. Por favor espere un minuto para continuar navegando en el portal.');
  }
  // const originalRequest = error.config;
  if (response.status === 401) {
    if ((router.currentRoute.path !== '/auth'
      && router.currentRoute.path !== '/auth/login'
      && router.currentRoute.path !== '/auth/forgot-password'
      && router.currentRoute.path !== '/auth/lockscreen'
      && router.currentRoute.path !== '/auth/404'
      && router.currentRoute.path !== '/auth/500')
      || (router.currentRoute.path === '/' && sessionStorage.getItem('session'))
      || (router.currentRoute.path === '/auth/login' && sessionStorage.getItem('session'))) {
      Message.error(data.msg);

      // Codigo para remover cualquier llave con nombre inicial session
      const sessionKeys = Object.keys(sessionStorage).filter((Key) => Key.includes('session'));
      sessionKeys.forEach((k) => {
        sessionStorage.removeItem(k);
      });

      store.state.id = '';
      store.state.nombre = '';
      store.state.email = '';
      store.state.es_activo = '';
      store.state.authorized = false;
      store.state.permisos = [];
      store.state.image = '';

      router.push('/auth');
    }
    if (data.msg) { Message.error(data.msg); }
  } else {
    if (data.msg) { Message.error(data.msg); }
    if (data.errors) {
      const errors = data.errors.map(({ msg }) => msg);
      Message.error(errors.join('<br>'));
    }

    return Promise.reject(error);
  }
});

export default apiClient;
