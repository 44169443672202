<template>
  <div id="app" :class="{ logo: mounted }">
    <div
      v-if="!$online"
      class="offline">¡Sin conexion a internet!</div>
    <v-scroll-to-top></v-scroll-to-top>
    <router-view />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapState } from 'vuex';

export default {
  name: 'app',
  data() {
    return {
      preventExit: true,
      mounted: false,
    };
  },
  computed: {
    ...mapState(['settings']),
    ...mapState('user', ['authorized', 'es_proveedor']),
    nextRoute() {
      const route = this.es_proveedor ? '/perfil' : '/';
      return route; // this.$route.query.redirect || route;
    },
    currentRoute() {
      return this.$route.path;
    },
  },
  // beforeMount() {
  //   window.addEventListener("beforeunload", this.preventNav)
  //   this.$once("hook:beforeDestroy", () => {
  //     window.removeEventListener("beforeunload", this.preventNav);
  //   })
  // },
  // beforeRouteLeave(to, from, next) {
  //     if (!window.confirm("Leave without saving?")) {
  //       return;
  //     }
  //   next();
  // },
  methods: {
    preventNav(event) {
      if (!this.preventExit) return;
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    },
  },
  mounted() {
    this.mounted = true;
    this.$store.dispatch('user/LOAD_CURRENT_ACCOUNT');
    this.$store.commit('SET_PRIMARY_COLOR', { color: this.settings.primaryColor });
    this.$store.commit('SET_PRIMARYDARK_COLOR', { color: this.settings.primaryDarkColor });
    this.$store.commit('SET_PRIMARYLIGHT_COLOR', { color: this.settings.primaryLightColor });
    this.$store.commit('SET_THEME', { theme: this.settings.theme });
    // STORED AGGRID STATE COLUMNS DATA
    const aggrids = Object.keys(this.$store.getters.stored_aggrids);
    aggrids.forEach((aggridName) => {
      const agState = window.localStorage.getItem(`app.aggrid.${aggridName}`) ? JSON.parse(window.localStorage.getItem(`app.aggrid.${aggridName}`)) : [];
      this.$store.commit('SAVE_AGGRIDSTATE', { tblname: aggridName, columnstate: agState });
    });
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    '$store.state.settings.theme'(theme) {
      this.$store.commit('SET_THEME', { theme });
    },
    authorized(authorized) {
      if (authorized) { // && (this.currentRoute === '/auth/login')) {
        this.$router.replace(this.nextRoute);
      }
    },
    // eslint-disable-next-line object-shorthand
    '$route'(to, from) {
      const query = { ...to.query };
      this.$store.commit('SETUP_URL_SETTINGS', query);
    },
  },
};

</script>

<style lang="scss">
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-balham.css";

#app {
 -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  align-items: center;
  height: 100%;
  position: relative;
  color: #2c3e50;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
}
#app.logo {
  background: url('./assets/background.jpg');
}

.offline {
  position: -webkit-sticky;
  position: sticky;
  z-index: 99;
  top: 0;
  text-align:center;
  color:white;
  background:#ff0000;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'
}
</style>
