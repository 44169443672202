<template>
  <div>
    <m-topbar/>
    <div class="main-container">
      <router-view/>
    </div>
    <m-footer />
  </div>
</template>
<script>
import MTopbar from './Topbar.vue';
import MFooter from './Footer.vue';

export default {
  name: 'AuthLayout',
  components: {
    MTopbar,
    MFooter,
  },
  data() {
    return {
    };
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  min-height: 100vh;
  align-items: center;
  background: rgb(42 42 41 / 72%); // rgba(42,42,41,.548);
}
</style>
