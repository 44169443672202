/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
export default {
  methods: {
    $delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    // Funcion para formato de moneda
    $currencyFormat(number, decimals = 2) {
      let result = number;
      if (!Number.isNaN(number) && (typeof number !== 'undefined' && number !== null)) {
        const parts = parseFloat(number).toFixed(decimals).toString().split('.');
        parts[0] = `$ ${parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
        result = parts.join('.');
      }

      return result;
    },
    $formatDate(date) {
      return date.toISOString().slice(0, 10);
    },
    $formatDateFromString(stringDate) {
      const date = new Date(stringDate);
      return date.toISOString().slice(0, 10);
    },
    $mxToDate(stringMxDate) { // dd-mm-yyyy TO yyyy-mm-dd
      const dateParts = stringMxDate.split('-');
      const date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      return date;
    },
    $formatDateTime(date) {
      return date.toISOString().slice(0, 19).replace('T', ' ');
    },
    $formatDateMonth(date) {
      return `${(date.getMonth() + 1).toString().padStart(2, '00')}-${date.getFullYear()}`;
    },
    $formatDateMX(date) {
      return `${date.getDate().toString().padStart(2, '00')}-${(date.getMonth() + 1).toString().padStart(2, '00')}-${date.getFullYear()}`;
    },
    $formatDateToString(date) {
      let isoDate = date.toISOString();
      isoDate = isoDate.replaceAll(/-/ig, '_');
      isoDate = isoDate.replaceAll(/T/ig, '_');
      isoDate = isoDate.replaceAll(/:/ig, '_');
      isoDate = isoDate.replaceAll(/Z/ig, '');
      return isoDate;
    },
    $formatNumber(number) {
      return number?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
    $formatXml(xml, tab = '\t') { // tab = optional indent value, default is tab (\t)
      let formatted = '';
      let indent = '';
      xml.split(/>\s*</).forEach((node) => {
        if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
        formatted += `${indent}<${node}>\r\n`;
        if (node.match(/^<?\w[^>]*[^/]$/)) indent += tab; // increase indent
      });
      return formatted.substring(1, formatted.length - 3);
    },
    $removeExtension(filename) {
      return filename.substring(0, filename.lastIndexOf('.')) || filename;
    },
    $overlayNoRowsTemplate() {
      return `
      <div class="empty-image">
        <img src="empty.svg" alt="Empty">
        <p class="empty-description">Sin datos</p>
      </div>`;
    },
    /* Método para crear dataSource de los datos recibidos
        *  @self(objec)         Instancia del 'this' en la vista
        *  @apiClient(object)   Instancia del apiClient en la vista
        *  @url(string)         Url de la consulta en la api que devuelve la data para el grid
        *  @filters(object)     Filtros en encabezado del frontend para filtrar el backend
        *  @renderData(string)  Nombre de la funcion para renderizar los datos recibidos del backend, antes de cargarlos al grid del frontend
        */
    $createDataSource(self, apiClient, url, filters = {}, renderData = false) {
      self.loading = true;
      const viewtypeValue = self.$route.query?.viewtype || 0;
      const viewtype = viewtypeValue === 0 ? 'full' : 'partial';
      self.agGrid.api.gridOptionsWrapper.gridOptions.serverSideStoreType = viewtype;
      const isInfinitiScroll = viewtypeValue === 1;
      self.agGrid.api.gridOptionsWrapper.gridOptions.serverSideInfiniteScroll = isInfinitiScroll;
      const dataSource = {
        getRows(request) {
          const { request: params } = request;
          // Obtenemos los parametros para la variable global (uso en reportes)
          self.filters = params;
          const currentPage = Number.isNaN(params.endRow / self.paginationPageSize) ? 1 : params.endRow / self.paginationPageSize;
          // Peticion en base al tipo de vista
          apiClient.get(`/${url}?viewtype=${viewtype}&page=${currentPage}`, {
            params: {
              results: self.paginationPageSize,
              ...params,
              ...filters,
            },
          })
            .then((res) => {
              const {
                data: {
                  data: apiData, total: apiTotal, to: apiTo, next_page_url,
                },
              } = res;
              let rowsThisPage;
              let lastRow;
              let totalRows;
              setTimeout(() => {
                if (apiData) {
                  const data = apiData;
                  // Validamos si existe el total de registros desde el backend sino, se genera en el frontend
                  const total = apiTotal || (next_page_url ? (apiTo || 0) + 1 : (apiTo || 0));
                  rowsThisPage = data.slice(0, data.length);
                  lastRow = -1;
                  totalRows = total;

                  if (totalRows <= params.endRow) {
                    lastRow = totalRows;
                  }
                } else {
                  const { data } = res;
                  rowsThisPage = data.slice(0, data.length);
                  lastRow = data.length;
                  totalRows = lastRow;
                }

                rowsThisPage = renderData ? self[renderData](rowsThisPage) : rowsThisPage;
                request.api.totalRows = totalRows;
                request.successCallback(rowsThisPage, lastRow);
                if (totalRows === 0) {
                  self.agGrid.api.showNoRowsOverlay();
                } else {
                  self.agGrid.api.hideOverlay();
                }
                // self.gridSizeChanged(self.agGrid);
              }, 0);

              self.loading = false;
            })
            .catch(() => {
              self.loading = false;
              request.successCallback([], 0);
            });
        },
      };
      return dataSource;
    },
    /* Método para crear el excel a partir de un Json
     *  @self(objec)         Instancia del 'this' en la vista
     *  @apiClient(object)   Instancia del apiClient en la vista
     *  @url(string)         Url de la consulta en la api que devuelve la data para exportar
     *  @filters(object)     Filtros en encabezado del frontend para filtrar el backend
     *  @XLSXSTYLE           Libreria  para generar XLSX
     *  @headerText
     */
    $exportExcel(params) {
      const {
        this: self,
        apiClient,
        XLSXSTYLE,
        url,
        title,
        headers,
        filtersApi = {},
        filtersExcel = [],
      } = params;

      const model = self.agGrid.api.getModel();
      const filterModel = model?.storeParams?.filterModel;
      const sortModel = model?.storeParams?.sortModel;

      let ws; // workshet
      let initRender = 2; // num de celda para empezar a renderizar la data

      apiClient
        .get(`/${url}`, {
          params: {
            filterModel,
            sortModel,
            ...filtersApi,
          },
        })
        .then((res) => {
        // let headingLeters = [A, 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'Z'];
          let { data } = res;

          if (data.length) {
          // se calcula el ancho para las celdas
            const headerCols = Object.keys(data[0]); // el nombre de las columnas
            const wscols = [];
            const mapCols = [];
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < headers.length; i++) {
              if (headerCols.includes(Object.keys(headers[i])[0])) {
                wscols.push({
                  wch: Object.values(headers[i])[0].length + 5,
                }); // arreglo que contiene medidas de los encabezados de las columnas
                mapCols.push(
                  `${Object.keys(headers[i])[0]
                  }:`
                  + `d.${
                    Object.keys(headers[i])[0]}`,
                ); // aprovechamos el recorrido para crear parte de la consulta que se ocupara para eliminar las columnas innecesarias del data
              }
            }

            // Eliminación de las columnas del data que no ocuparemos
            // eslint-disable-next-line no-unused-vars, no-eval
            data = data.map((d) => eval(`({${mapCols.join()}})`));

            // Agregar fila con nombre del reporte
            let empresa = sessionStorage.getItem('organization-name');
            empresa = empresa ? `${empresa} - ` : '';
            ws = XLSXSTYLE.utils.aoa_to_sheet([[`${empresa}${title}`]]);
            // Estilos del nombre del reporte
            ws.A1.s = {
              font: {
                bold: true,
                sz: 18,
              },
            };

            if (filtersExcel.length) {
            // En caso de tener filtros primero se indica en que linea va a comenzar a renderizar el json
              initRender = 3; // indica que inicia en A2
              const dataHeader = filtersExcel.map(
                (filter) => `${filter.name}: ${filter.data}`,
              ); // se anexa a un array ya que XLSX solo acepta este tipo de estructura

              // Se agrega el encabezado en caso de que existan los filtros en el reporte
              ws = XLSXSTYLE.utils.sheet_add_aoa(ws, [dataHeader], {
                origin: 'A2',
              });

              // Documentación
              // https://github.com/sheetjs/sheetjs#array-of-objects-input

              ws = XLSXSTYLE.utils.sheet_add_json(ws, data, {
                skipHeader: false,
                origin: 'A3',
              }); // se agregan los datos a la hoja de calculo
            } else {
              ws = XLSXSTYLE.utils.sheet_add_json(ws, data, {
                origin: 'A2',
              }); // se agregan los datos a la hoja de calculo
              initRender = 2;
            }

            const wb = XLSXSTYLE.utils.book_new(); // se crea el libro o  Workbook de Excel

            const range = XLSXSTYLE.utils.decode_range(ws['!ref']); // se decodifica el rango para saber donde va a customizar las celdas

            // Estilos de los encabezados
            // eslint-disable-next-line no-plusplus
            for (let C = range.s.c; C <= range.e.c; C++) {
              const address = XLSXSTYLE.utils.encode_col(C) + initRender; // <-- first row, column number C
              // eslint-disable-next-line no-continue
              if (!ws[address]) continue;
              // eslint-disable-next-line prefer-destructuring
              ws[address].v = Object.values(headers[C])[0];
              ws[address].s = {
                fill: {
                  patternType: 'solid',
                  fgColor: {
                    rgb: '971b1e',
                  },
                },
                border: {
                  top: { style: 'thin', color: { auto: 1 } },
                  right: { style: 'thin', color: { auto: 1 } },
                  bottom: { style: 'thin', color: { auto: 1 } },
                  left: { style: 'thin', color: { auto: 1 } },
                },
                font: {
                  color: { rgb: 'FFFFFF' },
                },
              };
              // Estilos de los filtros
              if (filtersExcel.length && C < filtersExcel.length) {
                ws[XLSXSTYLE.utils.encode_col(C) + 2].s = {
                  font: {
                    bold: true,
                  },
                };
              }
            }

            ws['!cols'] = wscols; // se indica el tamaño de las columnas
            // Add Worksheet to Workbook
            // Workbook contains one or more worksheets
            XLSXSTYLE.utils.book_append_sheet(wb, ws, title); // sheetAName is name of Worksheet
            // Export Excel file
            XLSXSTYLE.writeFile(wb, `${title}.xlsx`); // name of the file is 'book.xlsx'
          } else {
            self.$notification.warning({
              message: 'Exportar a Excel',
              description:
              'No existen datos a exportar con los filtros seleccionados',
            });
          }
        })
        .catch((err) => {
          self.loading = false;
          console.log(err);
        });
    },
  },
};
