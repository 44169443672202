<template>
  <div>
    <m-topbar :authorized="authorized" :es_proveedor="es_proveedor" :es_repse="es_repse"/>
    <div class="main-container">
      <div id="principal-container" class="container-app">
        <div class="row">
          <div class="col-12">
            <transition name="slide-fade" mode="out-in">
              <h2 class="section-title" :key="title">{{ title.toUpperCase() }}</h2>
            </transition>
            <transition name="slide-fade" mode="out-in">
              <hr class="title-line" :key="title">
            </transition>
          </div>
        </div>
        <div class="row panel">
          <div class="col-12">
            <router-view :es_proveedor="es_proveedor" :es_interno="es_interno" :es_repse="es_repse"/>
          </div>
        </div>
      </div>
    </div>
    <m-footer />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import MTopbar from './Topbar.vue';
import MFooter from './Footer.vue';
// eslint-disable-next-line import/no-cycle
// import apiClient from '@/services/axios';

export default {
  name: 'MainLayout',
  computed: {
    ...mapState(['settings', 'globals']),
    ...mapState('user', ['authorized', 'es_proveedor', 'es_interno', 'es_repse']),
    title() {
      return this.$route.meta.title;
    },
  },
  components: {
    MTopbar,
    MFooter,
  },
  data() {
    return {
      loading: false,
      modulos: [],
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
</style>
